import { IMAGE_FILES_EXTENSION } from "constants/app";
import { Flex, Image, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { Modal } from "global/Modals/ModalStory/styled";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi2";
import { IChatDetail } from "types/responses/chat";
import { downloadMedia, getMediaExtension, getMediaName } from "utils/media";

interface BubbleMessageProps {
  chat: IChatDetail;
  isAuthor?: boolean;
}

function BubbleMessage({ chat, isAuthor = false }: BubbleMessageProps) {
  const contentColor = isAuthor ? "$white" : "$coolGray500";
  const hasMedia = !!chat.media;
  const hasStory = chat.attachment_type === "story";

  const [showImage, setShowImage] = useState(false);

  const renderMedia = (media: string) => {
    if (!media) return null;
    const mediaExtension = getMediaExtension(media);
    const isImage = IMAGE_FILES_EXTENSION.includes(mediaExtension);

    if (isImage) {
      return (
        <Image
          src={media}
          w="100%"
          br="$rounded-md"
          css={{ cursor: "pointer" }}
          onClick={() => setShowImage(true)}
        />
      );
    }

    return (
      <Flex
        color={contentColor}
        alignItems="center"
        gap="8px"
        w="100%"
        onClick={() => downloadMedia(media)}
        cursor="pointer"
      >
        <HiDocumentText />
        <Text weight="$bold" flex="1" ellipsis>
          {getMediaName(media)}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      <Flex w="100%" justify={isAuthor ? "flex-end" : "flex-start"} mb="8px">
        <Flex
          direction="column"
          gap="4px"
          maxWidth="215px"
          alignItems={isAuthor ? "flex-end" : "flex-start"}
        >
          {hasStory && (
            <>
              <Text size="$xs" color="$coolGray500">
                Kamu membalas ceritanya:{" "}
              </Text>
              <Flex direction="column" gap="4px" width="139px">
                {renderMedia(chat.attachment.stories[0].media)}
              </Flex>
            </>
          )}
          <Flex
            direction="column"
            p="8px"
            bgColor={isAuthor ? "$secondary" : "$white"}
            gap="8px"
            border={`solid 1px ${contentColor}`}
            maxWidth="215px"
            css={{
              borderRadius: "8px",
              borderBottomLeftRadius: !isAuthor ? "0px" : "8px",
              borderBottomRightRadius: isAuthor ? "0px" : "8px",
            }}
          >
            {hasMedia && renderMedia(chat.media)}
            <Text color={contentColor}>{parse(chat.chat)}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        open={showImage}
        onCancel={() => setShowImage(false)}
        centered
        width={400}
      >
        <If condition={hasMedia}>
          <Image src={chat.media} w="100%" br="$rounded-md" />
        </If>
      </Modal>
    </>
  );
}

export default BubbleMessage;
