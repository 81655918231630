import { Image } from "components/Base";

function DownloadLogo() {
  return (
    <>
      <a
        href="https://play.google.com/store/apps/details?id=id.sigap.perjuangan&hl=id"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src="/images/play-store.svg" height="55px" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.sigap"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src="/images/app-store.svg" height="55px" />
      </a>
    </>
  );
}

export default DownloadLogo;
