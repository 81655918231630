import { Box } from "components/Base";

interface ContainerProps {
  children: React.ReactNode;
}

function Container({ children }: ContainerProps) {
  return (
    <Box w="100%" maxWidth="1196px" m="0 auto">
      {children}
    </Box>
  );
}

export default Container;
