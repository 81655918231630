import Header from "global/Header";
import Footer from "global/MainLayout/Footer";
import BannerHome from "./components/banner/Banner";
import Inspiration from "./components/Inspiration";
import List7D from "./components/list-7d/List7D";

function Home() {
  return (
    <>
      <Header />
      <BannerHome />
      <List7D />
      <Inspiration />
      <Footer />
    </>
  );
}

export default Home;
