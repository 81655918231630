import { Grid } from "components/Base";
import { styled } from "configs/stitches.config";

export const MainContent = styled("div", {
  minHeight: "calc(100vh - 64px)",
  m: "0 auto",
  my: "64px",
  py: "38px",
  width: "1196px",
});

export const FooterWrapper = styled("footer", {
  backgroundColor: "$primary1",
  position: "relative",
  mt: "60px",
  display: "none",

  "&::before": {
    content: "''",
    position: "absolute",
    top: "-58px",
    left: 0,
    width: "100%",
    height: "100%",
    background: "url('/images/footer-bg.png')",
  },

  "@md": {
    display: "block",
  },
});

export const FooterGrid = styled(Grid, {
  columns: "1fr",
  width: "100%",
  maxWidth: "1196px",
  m: "0 auto",
  py: "40px",
  gap: "48px",
  position: "relative",
  zIndex: 2,
  p: "32px",

  "@lg": {
    columns: "1fr 1fr",
    gap: "64px",
    p: "48px",

    "& .footer-menu": {
      pl: "20px",
    },
  },

  "@xl": {
    columns: "1fr 1fr 1fr",
    gap: "120px",
    p: "0",

    "& .footer-menu": {
      pl: "40px",
    },
  },
});
